import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
function ScrollToTop({ history, children }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            document.body.scrollTop = 0;
        });
        return () => {
            unlisten();
        };
    }, []);
    return React.createElement(React.Fragment, null, children);
}
export default withRouter(ScrollToTop);
